﻿// import * as echarts from 'echarts';
import { echartSetOption } from './echarts-utils';

/* -------------------------------------------------------------------------- */
/*     Report Summary Chart
/* -------------------------------------------------------------------------- */

const reportSummaryInit = () => {
  const reportSummaryChart = document.querySelector('.echart-report-summary');
  if (reportSummaryChart) {
    const dateInfo = document.querySelector('[date-last-test-timestamp]');
    const ts = Number(dateInfo.getAttribute('date-last-test-timestamp'));
    if (ts !== 0) {
      const userLang = navigator.language || navigator.userLanguage;

      const dt = new Date(ts);
      dateInfo.textContent = dt.toLocaleString(userLang, {
        dateStyle: 'medium',
        timeStyle: 'short'
      });
    }

    const { getData, getColor } = window.phoenix.utils;

    const userOptions = getData(reportSummaryChart, 'options');
    const chart = window.echarts.init(reportSummaryChart);

    const getDefaultOptions = () => ({
      color: [
        getColor('primary'),
        getColor('warning-light'),
        getColor('danger-light')
      ],

      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getColor('body-highlight-bg'),
        borderColor: getColor('border-color'),
        textStyle: { color: getColor('light-text-emphasis') },
        borderWidth: 1,
        transitionDuration: 0,
        position(pos, params, el, elRect, size) {
          const obj = { top: pos[1] - 35 }; // set tooltip position over 35px from pointer
          if (window.innerWidth > 540) {
            if (pos[0] <= size.viewSize[0] / 2) {
              obj.left = pos[0] + 20; // 'move in right';
            } else {
              obj.left = pos[0] - size.contentSize[0] - 20;
            }
          } else {
            obj[pos[0] < size.viewSize[0] / 2 ? 'left' : 'right'] = 0;
          }
          return obj;
        },
        formatter: params => {
          return `<strong>${params.name}:</strong> ${params.value}`;
        },
        extraCssText: 'z-index: 1000'
      },

      legend: { show: false },

      series: [
        {
          name: '',
          type: 'pie',
          radius: ['100%', '87%'],
          avoidLabelOverlap: false,
          emphasis: {
            scale: false,
            itemStyle: {
              color: 'inherit'
            }
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: getColor('body-bg')
          },
          label: {
            show: true,
            position: 'center',
            formatter: '{a}',
            fontSize: 23,
            color: getColor('light-text-emphasis')
          },
          data: []
        }
      ]
    });

    echartSetOption(chart, userOptions, getDefaultOptions);
  }
};

export default reportSummaryInit;
